<template>
    <div class=" ">
        <div class="card-header bg-light "><span><i class="fa fa-file-medical"> </i> Fragmingam</span> </div>
        <div class="card-body">
            <div class="row mt-2">     
                    <div class="col-6">Edad: {{data.edad_u}}</div>
                    <div class="col-6">Ta Sistólica: {{data.ta_sistolica}}</div>
                    <div class="col-6">Col Total: {{data.col_total}}</div>
                    <div class="col-6">Col Hdl: {{data.col_hdl}}</div>
                    <div class="col-6">Tratamiento Hipertensión: {{data.tratamiento_hipertension}}</div>
                    <div class="col-6">Fuma Cigarrillos: {{data.fuma_cigarrillos}}</div>
                    <div class="col-6">Presencia Diabetes: {{data.presencia_diabetes}}</div>
                    <div class="col-6">Porcentaje: {{data.porcentaje}}</div>
                    <div class="col-6">Riesgo: {{data.riesgo}}</div>  
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['idHistoria','data']
}
</script>